(function (root, factory) {
	if (typeof exports === 'object') {
		// Node. CommonJS-like
		module.exports = factory(require('./util.js'));
	}else {
		// Browser globals (root is window)
		root.PAULPASTA = root.PAULPASTA || {};
		root.PAULPASTA.navigation = factory(root.PAULPASTA.util);
	}
}(this, function(util) {
	if (util.default) {
		util = util.default;
	}

	const header = document.querySelector('#Header');
	const button = header.querySelector('.mobile-toggle');
	const body = document.querySelector('body');

	const toggleNavigation = (e) => {
		e.preventDefault();
		util.toggleClass('nav-open', body);
	}

	button.addEventListener('click', toggleNavigation);
}));