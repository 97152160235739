(function (root, factory) {
	if (typeof exports === 'object') {
		// Node. CommonJS-like
		module.exports = factory();
	}else {
		// Browser globals (root is window)
		root.PAULPASTA = root.PAULPASTA || {};
		root.PAULPASTA.util = factory();
	}
}(this, function() {

	const addClass = (targetClass, el) => {
		const arr = el.className.split(' ');
		if (arr.indexOf(targetClass) == -1) {
			el.className += ' ' + targetClass;
		}
	}

	const removeClass = (targetClass, el) => {
		if (el.classList) {
			el.classList.remove(targetClass);
		}else {
			el.className = el.className.replace(new RegExp('(^|\\b)' + targetClass.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	const hasClass = (targetClass, el) => {
		if (el.classList) {
			return el.classList.contains(targetClass);
		}else {
			return new RegExp('(^| )' + targetClass + '( |$)', 'gi').test(el.targetClass);
		}
	}

	const toggleClass = (targetClass, el) => {
		if (hasClass(targetClass, el)) {
			removeClass(targetClass, el);
		}else {
			addClass(targetClass, el);
		}
    }
    
    return {
        addClass: addClass,
        removeClass: removeClass,
        hasClass: hasClass,
        toggleClass: toggleClass
    }
}));