(function (root, factory) {
	if (typeof exports === 'object') {
		// Node. CommonJS-like
		module.exports = factory(require('./util.js'));
	}else {
		// Browser globals (root is window)
		root.PAULPASTA = root.PAULPASTA || {};
		root.PAULPASTA.themeManager = factory(root.PAULPASTA.util);
	}
}(this, function(util) {
	if (util.default) {
		util = util.default;
	}

	const body = document.querySelector('body');
	const sections = document.querySelectorAll('section[data-theme]');
	const default_theme = 'theme--default';
	let active_theme = default_theme;

	const update = () => {
		let target_theme = default_theme;
		sections.forEach((section) => {
			if (is_top_in_viewport(section)) {
				target_theme = section.getAttribute('data-theme');
			}
		});

		if (target_theme != active_theme) {
			util.removeClass(active_theme, body);
			util.addClass(target_theme, body);
			active_theme = target_theme;
		}
	}

	const is_top_in_viewport = (section) => {
		const bounding_box = section.getBoundingClientRect();
		const viewport_top = window.scrollY + window.innerHeight;
		const offset_percentage = 0.10;
		const offset = window.innerHeight * offset_percentage;

		return bounding_box.bottom + window.scrollY > viewport_top - offset && bounding_box.top + window.scrollY < viewport_top - offset;
	}

	if (util.hasClass('page--home', body)) {
		window.addEventListener('resize', update);
		window.addEventListener('scroll', update);
		update();
	}
}));